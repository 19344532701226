import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import loadable from '@loadable/component';

import { PageLoaderFallback } from '../../layout/PageLoaderFallback';

const Dobbeltboligforsikring = loadable(
  () => import(/* webpackPrefetch: true */ './general/DobbeltBoligforsikring'),
  { fallback: <PageLoaderFallback /> }
);
const EtakstNokkelenBoligsalg = loadable(
  () => import(/* webpackPrefetch: true */ './general/EtakstNokkelenBoligsalg'),
  { fallback: <PageLoaderFallback /> }
);
const FellesAmbisjon = loadable(
  () => import(/* webpackPrefetch: true */ './general/FellesAmbisjon'),
  { fallback: <PageLoaderFallback /> }
);
const KjopeEllerSelge = loadable(
  () => import(/* webpackPrefetch: true */ './general/KjopeEllerSelge'),
  { fallback: <PageLoaderFallback /> }
);
const Spoross = loadable(
  () => import(/* webpackPrefetch: true */ './general/Spoross'),
  { fallback: <PageLoaderFallback /> }
);
const TilrettelagtBoligsalg = loadable(
  () => import(/* webpackPrefetch: true */ './general/TilrettelagtBoligsalg'),
  { fallback: <PageLoaderFallback /> }
);
const TipsRadVedSalg = loadable(
  () => import(/* webpackPrefetch: true */ './general/TipsRadVedSalg'),
  { fallback: <PageLoaderFallback /> }
);
const SalgAvFritidsbolig = loadable(
  () => import(/* webpackPrefetch: true */ './park/SalgAvFritidsbolig'),
  { fallback: <PageLoaderFallback /> }
);
const SelgeKjopeFritidsbolig = loadable(
  () =>
    import(/* webpackPrefetch: true */ './sedberglian/SelgeKjopeFritidsbolig'),
  { fallback: <PageLoaderFallback /> }
);
const DinLokaleMegler = loadable(
  () => import(/* webpackPrefetch: true */ './vikoyr/DinLokaleMegler'),
  { fallback: <PageLoaderFallback /> }
);
const NoMatch = loadable(
  () => import(/* webpackPrefetch: true */ '../404Old'),
  { fallback: <PageLoaderFallback /> }
);

const Content: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/content/sporross" component={Spoross} />
      <Route
        exact
        path="/content/kjope-eller-selge-forst"
        component={KjopeEllerSelge}
      />
      <Route
        exact
        path="/content/felles-ambisjon-om-høyest-pris"
        component={FellesAmbisjon}
      />
      <Route
        exact
        path="/content/dobbeltboligforsikring"
        component={Dobbeltboligforsikring}
      />

      <Route
        exact
        path="/content/e-takst-nokkelen-til-boligsalg"
        component={EtakstNokkelenBoligsalg}
      />
      <Route
        exact
        path="/content/landingsside-park/vurderer-du-å-selge-din-fritidsbolig"
        component={SalgAvFritidsbolig}
      />
      <Route
        exact
        path="/content/selgekjopefritidsbolig"
        component={SelgeKjopeFritidsbolig}
      />
      <Route
        exact
        path="/content/selge-bolig-tips-rad"
        component={TipsRadVedSalg}
      />
      <Redirect
        exact
        from="/content/tipsradvedsalg/"
        to="/content/selge-bolig-tips-rad"
      />
      <Route
        exact
        path="/content/din-lokale-megler-vikoyr-brandt"
        component={DinLokaleMegler}
      />
      <Route
        exact
        path="/content/boligsalg-tilrettelagt-mot-smittefare"
        component={TilrettelagtBoligsalg}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Content;
